import React, { useEffect } from "react"
import { Slide, Fade } from "react-reveal"
import Slider from "react-slick"
import { Link } from "gatsby"
import { Markup } from "interweave"
import JSONAnimationHover from "../JSONAnimationHover/JSONAnimationHover"
import LighteningBolt from "./../../images/Animations/Lightening_bolt.json"
import Download from "./../../images/Animations/Download.json"
import Builder from "./../../images/Animations/Builder.json"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "./Report.scss"

export default function Report(data) {
  var settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    centerMode: true,
    centerPadding: "0",
    variableWidth: true,
    responsive: [
      {
        breakpoint: 10000,
        settings: "unslick",
      },
      {
        breakpoint: 1000,
        dots: false,
        arrows: true,
      },
      {
        breakpoint: 768,
        centerPadding: "0",
        centerMode: false,
      },
      {
        breakpoint: 480,
      },
    ],
  }
  const isBrowser = typeof window !== "undefined"
  let isMobile = false
  if (isBrowser && window.innerWidth <= 768) {
    isMobile = true
  } else {
    isMobile = false
  }

  useEffect(() => {
    function handleResize() {
      if (isBrowser && window.innerWidth <= 768) {
        isMobile = true
      } else {
        isMobile = false
      }
    }
    window.addEventListener("resize", handleResize)
  }, [])

  let durationTime = isMobile ? 0 : 1000

  return (
    <div className="container" id="explore">
      <div className="card-padding">
        <span className="spacer left"></span>
        <Slider {...settings} className="">
          <Slide bottom>
            <div bottom className="slider-item slide-1">
              <div className="card">
                <Fade
                  top
                  distance={"50px"}
                  delay={isMobile ? 0 : 1000}
                  duration={durationTime}
                >
                  <Link
                    to={data.data[0].href}
                    className="card-hover"
                    data-analytics={`{"event":"cta_interaction",
                      "click_text":"${data.data[0].title.text.replace(
                        /(?:\r\n|\r|\n)/g,
                        " "
                      )}",
                      "destination_url":"${data.data[0].href}",
                      "click_type":"internal",
                      "module":"Explore"
                    }`}
                  >
                    <div className="card-image ">
                      <Fade
                        top
                        delay={isMobile ? 0 : 1000}
                        duration={1000}
                        distance={"100px"}
                      >
                        <JSONAnimationHover
                          normal={LighteningBolt}
                          className="arrowImg"
                          hasHover={true}
                          loop={1}
                          waitToPlay="2000"
                        />
                      </Fade>
                    </div>
                    <div className="card-body">
                      <p className="card-text border-pink">
                        {data.data[0].description}
                      </p>
                      <span className="button card-link link-pink">
                        <Markup content={data.data[0].title.text}></Markup>
                      </span>
                    </div>
                  </Link>
                </Fade>
              </div>
            </div>
          </Slide>

          <Slide bottom delay={isMobile ? 0 : 300} duration={durationTime}>
            <div className=" slider-item slide-2">
              <div className="card">
                <Fade
                  top
                  distance={"50px"}
                  delay={isMobile ? 0 : 1400}
                  duration={durationTime}
                >
                  <Link
                    to={data.data[1].href}
                    className="card-hover"
                    data-analytics={`{"event":"cta_interaction",
                      "click_text":"${data.data[1].title.text.replace(
                        /(?:\r\n|\r|\n)/g,
                        " "
                      )}",
                      "destination_url":"${data.data[1].href}",
                      "click_type":"internal",
                      "module":"Explore"
                    }`}
                  >
                    <div className="card-image">
                      <Fade
                        top
                        delay={isMobile ? 0 : 1000}
                        duration={1000}
                        distance={"100px"}
                      >
                        <JSONAnimationHover
                          normal={Builder}
                          className="arrowImg"
                          hasHover={true}
                          loop={1}
                          waitToPlay="2300"
                        />
                      </Fade>
                    </div>
                    <div className="card-body">
                      <p className="card-text border-yellow">
                        {data.data[1].description}
                      </p>
                      <span className="button card-link link-yellow">
                        <Markup content={data.data[1].title.text}></Markup>
                      </span>
                    </div>
                  </Link>
                </Fade>
              </div>
            </div>
          </Slide>

          <Slide bottom delay={isMobile ? 0 : 600} duration={durationTime}>
            <div className="slider-item slide-3">
              <div className="card">
                <Fade
                  top
                  distance={"50px"}
                  delay={isMobile ? 0 : 1800}
                  duration={durationTime}
                >
                  <Link
                    to={data.data[2].href}
                    className="card-hover"
                    data-analytics={`{"event":"cta_interaction",
                      "click_text":"${data.data[2].title.text.replace(
                        /(?:\r\n|\r|\n)/g,
                        " "
                      )}",
                      "destination_url":"${data.data[2].href}",
                      "click_type":"internal",
                      "module":"Explore"
                    }`}
                  >
                    <div className="card-image">
                      <Fade
                        top
                        delay={isMobile ? 0 : 1000}
                        duration={1000}
                        distance={"100px"}
                      >
                        <JSONAnimationHover
                          normal={Download}
                          className="arrowImg"
                          loop={1}
                          waitToPlay="2600"
                        />
                      </Fade>
                    </div>
                    <div className="card-body">
                      <p className="card-text border-green">
                        {data.data[2].description}
                      </p>
                      <span className="button card-link link-green">
                        <Markup content={data.data[2].title.text}></Markup>
                      </span>
                    </div>
                  </Link>
                </Fade>
              </div>
            </div>
          </Slide>
        </Slider>
        <span className="spacer right"></span>
      </div>
    </div>
  )
}
